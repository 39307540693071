import React from "../_snowpack/pkg/react.js";
import ReactDOM from "../_snowpack/pkg/react-dom.js";
import "./index.css";
import {Home} from "./pages/Home.js";
import {Livestream} from "./parts/Livestream.js";
if (document.location.pathname.indexOf("/19793") > -1) {
  if (!document.querySelector("#page-element")) {
    let setMobile = function() {
      pageElement.style.height = "calc(100vh - 222px)";
      pageElement.style.top = "222px";
    }, setDesktop = function() {
      pageElement.style.height = "calc(100vh - 48px)";
      pageElement.style.top = "48px";
    };
    let pageElement = document.createElement("div");
    pageElement.id = "page-element";
    pageElement.style.zIndex = "9";
    pageElement.style.width = "100vw";
    if (document.body.clientWidth < 768) {
      setMobile();
    } else {
      setDesktop();
    }
    window.addEventListener("resize", () => {
      if (document.body.clientWidth < 768) {
        setMobile();
      } else {
        setDesktop();
      }
    });
    pageElement.style.left = "0px";
    pageElement.style.position = "absolute";
    document.body.style.overflow = "hidden";
    document.body.append(pageElement);
    let home = new Home();
    ReactDOM.render(/* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(home.render, null)), pageElement);
  }
} else {
  const livestreamElement = document.getElementById("livestream");
  if (livestreamElement) {
    ReactDOM.render(/* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(Livestream, null)), livestreamElement);
    const livestreamRowEl = document.getElementById("livestream-row");
    if (livestreamRowEl) {
      livestreamRowEl.style.display = "inherit";
    }
  }
}
