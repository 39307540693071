import {css} from "../../_snowpack/pkg/@emotion/css.js";
import {observer} from "../../_snowpack/pkg/mobx-react-lite.js";
import {makeAutoObservable} from "../../_snowpack/pkg/mobx.js";
import React from "../../_snowpack/pkg/react.js";
import {Livestream} from "../parts/Livestream.js";
function VideoRecordingPost(props) {
  return /* @__PURE__ */ React.createElement("div", {
    className: "wm-panel"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "wm-title wm-areapadding"
  }, props.title), /* @__PURE__ */ React.createElement("div", {
    className: "wm-content"
  }, /* @__PURE__ */ React.createElement(Livestream, {
    embedURL: props.videoSrc,
    justVideo: true,
    height: 400
  })));
}
export class Home {
  constructor() {
    this.counter = 0;
    this.updateCounter = () => {
      this.counter++;
    };
    this.render = observer(() => {
      return /* @__PURE__ */ React.createElement("div", {
        "data-component": "home",
        className: css`
                display:flex;
                flex-direction:column;
                width:100%;
                height:100%;
                background-color:whitesmoke;
                font-family:arial;

            `
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                    flex-grow:1;
                    display:flex;
                    flex-direction:column;    
                    overflow-y:auto;
                    overflow-x:hidden;
                    padding-bottom:50px;
                    box-sizing:border-box;
                `
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                    // background-color:#2980b9;
                    height:40px;
                    min-height:40px;
                    display:flex;
                    align-items:center;
                    margin-top:12px;
                    width:calc(100% - 16px);
                    position:fixed;

                    .side{
                        flex-grow:1;
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        // background-color:#2980b9;
                        height:100%;

                    }
                    
                    .middle{
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        min-width:300px;
                        max-width:680px;
                        width:90%;
                        height:100%;
                        background-color:#0284C7;
                        color:#F9FAFB;
                        font-size:1.5rem;
                        border-radius:0.5rem;
                    }

                    .wm-link{
                        cursor:pointer;
                        flex-grow:1;
                        display:flex;
                        justify-content:center;
                    }
                `
      }, /* @__PURE__ */ React.createElement("div", {
        className: "side"
      }), /* @__PURE__ */ React.createElement("div", {
        className: "middle"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-link",
        onClick: () => {
          window.open("https://www.lelandmanagement.com/community_payment/index_new", "_self");
        }
      }, "Payments"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-link",
        onClick: () => {
          window.open("https://www.lelandmanagement.com/community_portal/documents_expandable", "_self");
        }
      }, "Documents"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-link",
        onClick: () => {
          window.open("https://www.lelandmanagement.com/community_portal/content?id=19805", "_self");
        }
      }, "FAQ's")), /* @__PURE__ */ React.createElement("div", {
        className: "side"
      })), /* @__PURE__ */ React.createElement("div", {
        className: css`
                        width:100%;
                        display:flex;
                        flex-direction:column;
                        justify-content:center;
                        align-items:center;

                        

                        .wm-panel {
                            min-width:300px;
                            max-width:680px;
                            width:90%;
                            background-color:#075985;
                            margin-top:24px;
                            min-height:100px;
                            display:flex;
                            flex-direction:column;
                            border-radius:0.5rem;
                            overflow:hidden;
                        }

                        .wm-areapadding{
                            box-sizing:border-box;
                            padding:1rem;
                        }

                        .wm-title{
                            height:50px;
                            min-height:50px;
                            color:#F9FAFB;
                            font-size:1.25rem;
                            line-height:1;
                        }

                        .wm-content{
                            flex-grow:1;
                        }

                        .wm-footer{
                            min-height:70px;
                            background-color:green;
                            background-color:#334155;
                            color:#F9FAFB;
                            display:flex;
                            justify-content:center;
                            flex-direction:column;
                        }

                        .wm-footer > div {
                            box-sizing:border-box;
                            padding:6px 0px;
                        }

                        a{
                            color: white;
                            box-sizing:border-box;
                            padding:12px 12px;
                            margin:4px 0px;
                            background-color:#15803D;
                            border-radius:0.5rem;
                            width:max-content;
                        }
                    `
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                            height:48px;
                            min-height:48px;
                        `
      }), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Calendar"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("iframe", {
        height: "525px",
        src: "https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showTitle=0&showNav=1&showDate=1&showPrint=0&showTabs=1&showCalendars=0&mode=MONTH&title&src=bTB0dnMxbmlyOXNxMDFtbnVvamY5aDIxdWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23039BE5",
        style: {border: "1px solid #cccccc", boxSizing: "border-box"},
        width: "100%"
      }))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Gym Equipment Tutorials"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Operating the LED Console"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/569064802?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Operating the Rower"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/160013504?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Proper Rowing Technique"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/159965378?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Cycle Console - General Functionality"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/663105473?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Cycle Console - Console Demo 1"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/663105665?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Cycle Console - Console Demo 2"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/663105522?dnt=1&app_id=122963",
        width: "100%"
      }))), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", {
        className: "row"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "col-xs-12 col-sm-3 col-md-2"
      }, /* @__PURE__ */ React.createElement("img", {
        className: "footer-logo",
        src: "/ckeditor_assets/pictures/20274/content_watermark_logo_png.png"
      })), /* @__PURE__ */ React.createElement("div", {
        className: "col-xs-12 col-sm-7 col-sm-offset-1 col-md-9"
      }, /* @__PURE__ */ React.createElement("h3", null, "Watermark at Horizon West"), /* @__PURE__ */ React.createElement("p", null, "9410 Holliston Creek Pl, Winter Garden, FL 34787", /* @__PURE__ */ React.createElement("br", null), "(407) 614-5956"))), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("br", null))));
    });
    makeAutoObservable(this);
  }
}
