import {css} from "../../_snowpack/pkg/@emotion/css.js";
import {observer} from "../../_snowpack/pkg/mobx-react-lite.js";
import React from "../../_snowpack/pkg/react.js";
export const Livestream = observer((props) => {
  const embedURLBase = props.embedURL ?? ``;
  const autoplayOn = false;
  return /* @__PURE__ */ React.createElement("div", {
    className: css`
            width:100%;
            // height:600px;
            display:flex;
            flex-direction:column;
        `
  }, /* @__PURE__ */ React.createElement("div", {
    className: css`
                ${props.height ? `height:${props.height}px;` : "height:600px;"}
            `
  }, /* @__PURE__ */ React.createElement("iframe", {
    width: "100%",
    height: "100%",
    className: css`border:0px solid black;`,
    src: `${embedURLBase}?autoplay=${autoplayOn ? "1" : "0"}`,
    title: "YouTube video player",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: true
  })), !props.justVideo && /* @__PURE__ */ React.createElement("div", {
    className: css`
                // height:400px;
                background-color:#efefef;
                padding:20px;
                box-sizing:border-box;
            `
  }, /* @__PURE__ */ React.createElement("div", null, "Above is a recording of the last meeting where the next budget was discussed."), /* @__PURE__ */ React.createElement("h2", null, "Next Meeting: Thursday, November 18th @ 6:30 pm"), /* @__PURE__ */ React.createElement("div", null, "Annual Meeting"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("br", null)));
});
