import {css} from "../../_snowpack/pkg/@emotion/css.js";
import {observer} from "../../_snowpack/pkg/mobx-react-lite.js";
import {makeAutoObservable} from "../../_snowpack/pkg/mobx.js";
import React from "../../_snowpack/pkg/react.js";
import {Livestream} from "../parts/Livestream.js";
function VideoRecordingPost(props) {
  return /* @__PURE__ */ React.createElement("div", {
    className: "wm-panel"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "wm-title wm-areapadding"
  }, props.title), /* @__PURE__ */ React.createElement("div", {
    className: "wm-content"
  }, /* @__PURE__ */ React.createElement(Livestream, {
    embedURL: props.videoSrc,
    justVideo: true,
    height: 400
  })));
}
export class Home {
  constructor() {
    this.counter = 0;
    this.updateCounter = () => {
      this.counter++;
    };
    this.render = observer(() => {
      return /* @__PURE__ */ React.createElement("div", {
        "data-component": "home",
        className: css`
                display:flex;
                flex-direction:column;
                width:100%;
                height:100%;
                background-color:whitesmoke;
                font-family:arial;

            `
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                    flex-grow:1;
                    display:flex;
                    flex-direction:column;    
                    overflow-y:auto;
                    overflow-x:hidden;
                    padding-bottom:50px;
                    box-sizing:border-box;
                `
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                    // background-color:#2980b9;
                    height:40px;
                    min-height:40px;
                    display:flex;
                    align-items:center;
                    margin-top:12px;
                    width:calc(100% - 16px);
                    position:fixed;

                    .side{
                        flex-grow:1;
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        // background-color:#2980b9;
                        height:100%;

                    }
                    
                    .middle{
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        min-width:300px;
                        max-width:680px;
                        width:90%;
                        height:100%;
                        background-color:#0284C7;
                        color:#F9FAFB;
                        font-size:1.5rem;
                        border-radius:0.5rem;
                    }

                    .wm-link{
                        cursor:pointer;
                        flex-grow:1;
                        display:flex;
                        justify-content:center;
                    }
                `
      }, /* @__PURE__ */ React.createElement("div", {
        className: "side"
      }), /* @__PURE__ */ React.createElement("div", {
        className: "middle"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-link",
        onClick: () => {
          window.open("https://www.lelandmanagement.com/community_payment/index_new", "_self");
        }
      }, "Payments"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-link",
        onClick: () => {
          window.open("https://www.lelandmanagement.com/community_portal/documents_expandable", "_self");
        }
      }, "Documents"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-link",
        onClick: () => {
          window.open("https://www.lelandmanagement.com/community_portal/content?id=19805", "_self");
        }
      }, "FAQ's")), /* @__PURE__ */ React.createElement("div", {
        className: "side"
      })), /* @__PURE__ */ React.createElement("div", {
        className: css`
                        width:100%;
                        display:flex;
                        flex-direction:column;
                        justify-content:center;
                        align-items:center;

                        

                        .wm-panel {
                            min-width:300px;
                            max-width:680px;
                            width:90%;
                            background-color:#075985;
                            margin-top:24px;
                            min-height:100px;
                            display:flex;
                            flex-direction:column;
                            border-radius:0.5rem;
                            overflow:hidden;
                        }

                        .wm-areapadding{
                            box-sizing:border-box;
                            padding:1rem;
                        }

                        .wm-title{
                            height:50px;
                            min-height:50px;
                            color:#F9FAFB;
                            font-size:1.25rem;
                            line-height:1;
                        }

                        .wm-content{
                            flex-grow:1;
                        }

                        .wm-footer{
                            min-height:70px;
                            background-color:green;
                            background-color:#334155;
                            color:#F9FAFB;
                            display:flex;
                            justify-content:center;
                            flex-direction:column;
                        }

                        .wm-footer > div {
                            box-sizing:border-box;
                            padding:6px 0px;
                        }

                        a{
                            color: white;
                            box-sizing:border-box;
                            padding:12px 12px;
                            margin:4px 0px;
                            background-color:#15803D;
                            border-radius:0.5rem;
                            width:max-content;
                        }
                    `
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                            height:48px;
                            min-height:48px;
                        `
      }), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Hurricane Preparedness"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("iframe", {
        height: "600px",
        src: "https://www.lelandmanagement.com/community_portal/document/61713",
        style: {boxSizing: "border-box"},
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", {
        style: {display: "flex", justifyContent: "center", textAlign: "center", margin: "8px 16px"}
      }, /* @__PURE__ */ React.createElement("a", {
        href: "https://www.orangecountyfl.net/EmergencySafety/EmergencyInformation.aspx"
      }, "orangecountyfl.net/EmergencySafety/EmergencyInformation")))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Calendar"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("iframe", {
        height: "525px",
        src: "https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showTitle=0&showNav=1&showDate=1&showPrint=0&showTabs=1&showCalendars=0&mode=MONTH&title&src=bTB0dnMxbmlyOXNxMDFtbnVvamY5aDIxdWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23039BE5",
        style: {border: "1px solid #cccccc", boxSizing: "border-box"},
        width: "100%"
      }))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Gym Equipment Tutorials"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Operating the LED Console"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/569064802?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Operating the Rower"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/160013504?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Proper Rowing Technique"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/159965378?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Cycle Console - General Functionality"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/663105473?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Cycle Console - Console Demo 1"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/663105665?dnt=1&app_id=122963",
        width: "100%"
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("h3", {
        style: {color: "white", paddingLeft: "16px"}
      }, "Cycle Console - Console Demo 2"), /* @__PURE__ */ React.createElement("iframe", {
        height: "380px",
        src: "https://player.vimeo.com/video/663105522?dnt=1&app_id=122963",
        width: "100%"
      }))), /* @__PURE__ */ React.createElement(VideoRecordingPost, {
        title: "November 17th 2022 Annual Meeting",
        videoSrc: "https://www.youtube.com/embed/TV-H-tIAd-4"
      }), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "3rd Quarterly Newsletter - October 2022"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("iframe", {
        height: "900px",
        src: "https://www.lelandmanagement.com/system/documents/documents/000/053/889/original/October_2022_Watermark_Newsletter-compressed_1.pdf?1666830444",
        style: {border: "1px solid #cccccc", boxSizing: "border-box"},
        width: "100%"
      }))), /* @__PURE__ */ React.createElement(VideoRecordingPost, {
        title: "October 19th 2022 Board Meeting",
        videoSrc: "https://www.youtube.com/embed/BZLUr4-y_ig"
      }), /* @__PURE__ */ React.createElement(VideoRecordingPost, {
        title: "September 26th 2022 Board Meeting",
        videoSrc: "https://www.youtube.com/embed/YAjPqcon2UE"
      }), /* @__PURE__ */ React.createElement(VideoRecordingPost, {
        title: "August 24th 2022 Part 1 Board Meeting",
        videoSrc: "https://www.youtube.com/embed/K1BlYtNyqoM"
      }), /* @__PURE__ */ React.createElement(VideoRecordingPost, {
        title: "August 24th 2022 Part 2 Board Meeting",
        videoSrc: "https://www.youtube.com/embed/GG5GKLqDwkU"
      }), /* @__PURE__ */ React.createElement(VideoRecordingPost, {
        title: "July 28th Board Meeting",
        videoSrc: "https://www.youtube.com/embed/r2yQKDlUj-I"
      }), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "2nd Quarterly Newsletter - July 2022"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("iframe", {
        height: "900px",
        src: "https://www.lelandmanagement.com/system/documents/documents/000/050/687/original/July_Watermark_Newsletter.pdf?1657895040",
        style: {border: "1px solid #cccccc", boxSizing: "border-box"},
        width: "100%"
      }))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "May 25th 2022 Board Meeting"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement(Livestream, {
        embedURL: "https://www.youtube.com/embed/NX5-E-AjPrM",
        justVideo: true,
        height: 400
      })), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }, /* @__PURE__ */ React.createElement("a", {
        target: "_blank",
        href: "https://us02web.zoom.us/j/86763292244?pwd=UlRPOTF4cnJxKzR2azZLRFUzbitOZz09"
      }, "Connect")), /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("b", null, "Minutes to be approved in June meeting."), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "ID:"), "  867 6329 2244"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "Passcode:"), "  243028"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", null, "Meeting will also be restreamed here, but to speak you will need to connect via Zoom"))), /* @__PURE__ */ React.createElement(VideoRecordingPost, {
        title: "April 18th 2022 Board Meeting",
        videoSrc: "https://www.youtube.com/embed/_TTQVaO-ehI"
      }), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "April 11th 2022 Landscape Meeting"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement(Livestream, {
        embedURL: "https://www.youtube.com/embed/WusX4Pmo6Js",
        justVideo: true,
        height: 400
      })), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }, /* @__PURE__ */ React.createElement("a", {
        target: "_blank",
        href: "https://us02web.zoom.us/j/85616802551?pwd=MTdUSTUzVzk4V2svOGp2c0pYSUNVUT09"
      }, "Connect")), /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("b", null, "Minutes to be approved in April meeting."), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "ID:"), "  856 1680 2551"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "Passcode:"), "  892241"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", null, "Meeting will also be restreamed here, but to speak you will need to connect via Zoom"))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "March 29th Board Meeting"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement(Livestream, {
        embedURL: "https://www.youtube.com/embed/k22ONNQWyHY",
        justVideo: true,
        height: 400
      })), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }, /* @__PURE__ */ React.createElement("a", {
        target: "_blank",
        href: "https://us02web.zoom.us/j/83672719397?pwd=MGtMalQ5dEZwWWI2Rm5JVkY2dzUrdz09"
      }, "Connect")), /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("b", null, "Minutes to be approved in April meeting."), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "ID:"), "  836 7271 9397"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "Passcode:"), "  862742"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", null, "Meeting will also be restreamed here, but to speak you will need to connect via Zoom"))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Feb 15th Board Meeting"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement(Livestream, {
        embedURL: "https://www.youtube.com/embed/PVOLZVzZTe4",
        justVideo: true,
        height: 400
      })), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }, /* @__PURE__ */ React.createElement("a", {
        target: "_blank",
        href: "https://us02web.zoom.us/j/86370487488?pwd=bHpEODIwTUN1ZDJtN0ZyTCsvWnkwQT09"
      }, "Connect")), /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("b", null, "Minutes to be approved in March meeting."), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "ID:"), "  863 7048 7488"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "Passcode:"), "  087297"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", null, "Meeting will also be restreamed here, but to speak you will need to connect via Zoom"))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Calendar"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("iframe", {
        height: "525px",
        src: "https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showTitle=0&showNav=1&showDate=1&showPrint=0&showTabs=1&showCalendars=0&mode=MONTH&title&src=bTB0dnMxbmlyOXNxMDFtbnVvamY5aDIxdWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23039BE5",
        style: {border: "1px solid #cccccc", boxSizing: "border-box"},
        width: "100%"
      }))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Jan 20th Board Meeting"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement(Livestream, {
        embedURL: "https://www.youtube.com/embed/xM7Q0rGQjk0",
        justVideo: true,
        height: 400
      })), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }, /* @__PURE__ */ React.createElement("a", {
        target: "_blank",
        href: "https://us02web.zoom.us/j/81551906701?pwd=RWh4czJRcjhOejVyaEh2U2hCSjltZz09"
      }, "Connect")), /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("b", null, "Minutes to be approved in February meeting."), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "ID:"), "  815 5190 6701"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("b", null, "Passcode:"), "  333471"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", null, "Meeting will also be restreamed here, but to speak you will need to connect via Zoom"))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Approved Budget 2022"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }, /* @__PURE__ */ React.createElement("a", {
        className: css`background-color:#92400E !important;`,
        target: "_blank",
        href: "https://drive.google.com/file/d/161LTZ0ZalQt9OMOyWNR-x_0NZegAjn8q/view"
      }, "Approved Budget 2022 Doc")))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Recent Survey Results"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }, /* @__PURE__ */ React.createElement("a", {
        className: css`background-color:#92400E !important;`,
        target: "_blank",
        href: "https://docs.google.com/presentation/d/e/2PACX-1vT3hHOUVLhLkRYQ7kTv72XuG2xFPQhw_WWMMF0oDFAWsAong6npB0GVrE3EnjcJcA/pub?start=false&loop=false&delayms=10000&slide=id.p1"
      }, "Survey Results Doc")))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Dec 2nd Board Meeting"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement(Livestream, {
        embedURL: "https://www.youtube.com/embed/kwSAfZO5tE4",
        justVideo: true,
        height: 400
      })), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }, /* @__PURE__ */ React.createElement("a", {
        className: css`background-color:#6D28D9 !important;`,
        target: "_blank",
        href: "https://mcusercontent.com/4f27a02a00ef8c4329d3d0300/files/bff1919e-8c66-9c54-f9d4-3083dc70efe5/2021_12_02_Agenda_Watermark.pdf"
      }, "Agenda")), /* @__PURE__ */ React.createElement("div", {
        className: css`
                                    height:50px;
                                    display:flex;
                                    align-items:center;
                                    gap:10px;
                                `
      }, /* @__PURE__ */ React.createElement("a", {
        className: css`background-color:#92400E !important;`,
        target: "_blank",
        href: "https://drive.google.com/file/d/148IUMXXB3mwBEJ20SAT3YgSBhNMb4NDr/view"
      }, "Summary"), /* @__PURE__ */ React.createElement("a", {
        className: css`background-color:#92400E !important;`,
        target: "_blank",
        href: "https://docs.google.com/presentation/d/e/2PACX-1vQQo4cSLcp8OYdP3xyAK1SGcIChPdJd2rr4FWOhYYBluzAUq8YKQbpeu2_4uN5gGw/pub?start=false&loop=false&delayms=10000&slide=id.p1"
      }, "Playground Doc"), /* @__PURE__ */ React.createElement("a", {
        className: css`background-color:#92400E !important;`,
        target: "_blank",
        href: "https://drive.google.com/file/d/1RYel7PFMh0fz3O0A9c_l2TQ3P1_P-iYP/view"
      }, "Greens Committee Doc")), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("b", null, "Minutes to be approved in January meeting."), /* @__PURE__ */ React.createElement("div", null, "Octobers Meeting Minutes were approved and are linked along side the associated video below."))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Oct 28th 2021 Board Meeting (Budget)"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                `
      }, /* @__PURE__ */ React.createElement(Livestream, {
        embedURL: "https://www.youtube.com/embed/DK5V31k-yGk",
        justVideo: true,
        height: 300
      }))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("a", {
        href: "https://www.lelandmanagement.com/system/documents/documents/000/045/780/original/2021-10-28__Minutes_-_Watermark.pdf?1638570049",
        target: "_blank"
      }, "Meeting Minutes"), /* @__PURE__ */ React.createElement("a", {
        className: css`background-color:#92400E !important;`,
        target: "_blank",
        href: "https://drive.google.com/file/d/161LTZ0ZalQt9OMOyWNR-x_0NZegAjn8q/view"
      }, "Approved Budget 2022 Doc"))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Sept 28th 2021 Board Meeting"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                `
      }, /* @__PURE__ */ React.createElement(Livestream, {
        embedURL: "https://www.youtube.com/embed/REBltliAbk0",
        justVideo: true,
        height: 300
      }))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("a", {
        href: "https://www.lelandmanagement.com/system/documents/documents/000/045/090/original/2021-09-28__Minutes-_Watermark.pdf?1635514915",
        target: "_blank"
      }, "Meeting Minutes"))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-panel"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "wm-title wm-areapadding"
      }, "Sept 9th 2021 Board Meeting (Road Connect)"), /* @__PURE__ */ React.createElement("div", {
        className: "wm-content"
      }, /* @__PURE__ */ React.createElement("div", {
        className: css`
                                `
      }, /* @__PURE__ */ React.createElement(Livestream, {
        embedURL: "https://www.youtube.com/embed/RzCHI5wIjfU",
        justVideo: true,
        height: 300
      }))), /* @__PURE__ */ React.createElement("div", {
        className: "wm-footer wm-areapadding"
      }, /* @__PURE__ */ React.createElement("a", {
        href: "https://www.lelandmanagement.com/system/documents/documents/000/044/536/original/2021-09-09__Meeting_Minutes_-_Watermark.pdf?1633620251",
        target: "_blank"
      }, "Meeting Minutes"))), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", {
        className: "row"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "col-xs-12 col-sm-3 col-md-2"
      }, /* @__PURE__ */ React.createElement("img", {
        className: "footer-logo",
        src: "/ckeditor_assets/pictures/20274/content_watermark_logo_png.png"
      })), /* @__PURE__ */ React.createElement("div", {
        className: "col-xs-12 col-sm-7 col-sm-offset-1 col-md-9"
      }, /* @__PURE__ */ React.createElement("h3", null, "Watermark at Horizon West"), /* @__PURE__ */ React.createElement("p", null, "9410 Holliston Creek Pl, Winter Garden, FL 34787", /* @__PURE__ */ React.createElement("br", null), "(407) 614-5956"))), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("br", null))));
    });
    makeAutoObservable(this);
  }
}
